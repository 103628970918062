$CircularStdBookW2: "../fonts/CircularStd/CircularStd-Book.woff2";
$CircularStdBookW: "../fonts/CircularStd/CircularStd-Book.woff";

$CircularStdMediumW2: "../fonts/CircularStd/CircularStd-Medium.woff2";
$CircularStdMediumW: "../fonts/CircularStd/CircularStd-Medium.woff";

$CircularStdBoldW2: "../fonts/CircularStd/CircularStd-Bold.woff2";
$CircularStdBoldW: "../fonts/CircularStd/CircularStd-Bold.woff";

$CircularStdBlackW2: "../fonts/CircularStd/CircularStd-Black.woff2";
$CircularStdBlackW: "../fonts/CircularStd/CircularStd-Black.woff";

@font-face {
    font-family: 'CircularStd';
    font-weight: 300;
    src: url($CircularStdBookW2) format("woff2"),
         url($CircularStdBookW) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    font-weight: 400;
    src: url($CircularStdMediumW2) format("woff2"),
         url($CircularStdMediumW) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    font-weight: 500;
    src: url($CircularStdBoldW2) format("woff2"),
         url($CircularStdBoldW) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    font-weight: 600;
    src: url($CircularStdBlackW2) format("woff2"),
         url($CircularStdBlackW) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    font-weight: 400;
    src: url(../fonts/cerebri-sans/regular.eot);
    src: url(../fonts/cerebri-sans/regular.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    font-weight: 500;
    src: url(../fonts/cerebri-sans/medium.eot);
    src: url(../fonts/cerebri-sans/medium.woff) format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'Cerebri Sans';
    font-weight: 600;
    src: url(../fonts/cerebri-sans/semibold.eot);
    src: url(../fonts/cerebri-sans/semibold.woff) format("woff");
    font-display: swap;
}
