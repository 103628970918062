button {
    max-width: 100%;

    &.btn {
        display: inline-block;
        padding: 0.5rem 0.75rem;

        font-family: "roboto",sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;

        transition: all .25s ease;

        --button-font-colour: white;
        --button-background-colour: black;
        --button-border-colour: black;

        &--primary {
            --button-background-colour: #2c7be5;
            --button-border-colour: #2c7be5;

            &:hover {
                --button-background-colour: #1a68d1;
                --button-border-colour: #1a68d1;
            }
        }

        &:disabled {
            cursor: auto;

            --button-background-colour: var(--colour-disabled);
            --button-border-colour: var(--colour-disabled);
            --button-font-colour: var(--colour-disabled-font);
        }

        color: var(--button-font-colour);
        background-color: var(--button-background-colour);
        border: 1px solid var(--button-border-colour);
        border-radius: 0.375rem;
    }
}
