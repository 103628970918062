@import "breakpoints";

.input__t-container {
    position: relative;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
        user-select: none;
        pointer-events: none;
    }

    &.visible {
        .input__tooltip {
            transition: opacity .15s ease;
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.label__tooltip {
    display: flex;
    flex-direction: row;
}

.card-container {
    position: relative;

    background: #FFFFFF;
    border: 1px solid var(--colour-border);
    box-sizing: border-box;
    box-shadow: 0 4px 15px rgba(23, 31, 70, 0.16);
    border-radius: 6px;

    padding: 1rem;
}

.card-swappable-inplace {
    @media only screen and (min-width: $Laptop) {
        position: absolute;
    }

    &.animation-slide-out {
        position: absolute;
    }
}

.card-container, .funnel-default-card {
    .inner-container-header {
        &:not(.show) {
            .toggle-container {
                &:hover:not(.small, .tiny, .minuscule) {
                    background-color: #deedf6;
                    border-radius: 6px;
                }
            }
        }

        &.show {
            .toggle-container {
                background-color: #deedf6;
                border-radius: 6px 6px 0 0;
            }
        }
    }

    .inner-container {
        position: relative;
        background-color: var(--color-primary-background);
        border-radius: 6px;
        padding: .5rem;
        margin: .33rem 0 1rem;

        max-height: 44px;
        overflow: hidden;

        transition: all .25s ease;

        &-attached {
            max-height: 0;
            margin: 0 0 1rem;
            border-radius: 0 0 6px 6px;

            &:not(.show) {
                opacity: 0;
            }
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: left;
            cursor: pointer;
            transition: all .25s ease;
            user-select: none;
            z-index: 1;

            margin: -.5rem -.5rem 1rem -.5rem;
            padding: .5rem;

            label {
                cursor: pointer;
                font-weight: 400;
            }

            &:hover {
                background-color: var(--color-primary-background-light);
            }

            img {
                margin-left: .5rem;
                height: 28px;
                width: 28px;
            }
        }

        .restore-button {
            position: absolute;

            opacity: 0;
            pointer-events: none;
            transition: all .25s ease;
            height: 44px;
            width: 44px;
            padding: 6px;
            top: 0;
            right: 0;
            cursor: pointer;

            &:hover {
                background-color: var(--color-primary-background-light);
            }

            &.dirty {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &.show {
            max-height: 1000px;
            overflow: visible;

            .restore-button {
                border-radius: 0 0 0 6px;
            }
        }
    }
}

@mixin input-addon() {
    display: table-cell;
    vertical-align: middle;
    background-color: #f5f5f5;
    border: 1px solid var(--colour-border);
    white-space: nowrap;
    width: 1%;

    color: #555555;
    font-size: 16px;
    line-height: 1.33;
    //font-family: "FuturaPtBook", Arial, sans-serif;

    padding: 0 8px;

    margin: 0.25rem 0 1rem 0;

    img {
        user-select: none;
        margin-top: 6px;
        width: 24px;
        max-height: 24px;
    }

    @media only screen and (max-width: $Mobile) {
        font-size: 14px;
        padding: 0 6px;
    }
}

select {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    margin-bottom: 1rem;

    font-family: var(--default-fonts);
    font-weight: 300;
    font-size: 1.1rem;
    color: #282828;
    line-height: 1.5;

    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid var(--colour-border);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
                box-shadow .15s ease-in-out;

    option {
        font-weight: 300;
    }
}

.select {
    @include input-addon();
    user-select: none;
    cursor: pointer;
    position: relative;

    height: 48px;
    border-radius: 5px;
    -webkit-border-radius: 5px;

    &.open {
        border-radius: 5px 5px 0 0;
        -webkit-border-radius: 5px 5px 0 0;
    }

    &-left {
        min-width: 175px;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;

        @media only screen and (max-width: $Mobile) {
            min-width: 0;
        }
    }

    &-right {
        width: auto;
        border-left: 0;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    span {
        font-size: 12px;
        vertical-align: top;

        img {
            height: 100%;
            width: 16px;
            padding-left: 4px;
        }
    }

    &-label {
        min-height: 32px;
        display: flex;
        flex-direction: row;

        label {
            margin: auto 8px;
            transform: translateY(1px);
            cursor: pointer;
        }

        img {
            margin: auto 0;
            width: 32px;
            max-height: 32px;

            @media only screen and (max-width: $Mobile) {
                width: 24px;
                max-height: 24px;
            }
        }
    }

    &.small {
        height: 32px;

        .select-label {
            min-height: 24px;

            label {
                font-size: 1rem;
                line-height: 24px;
            }

            span img {
                width: 18px;
            }

            img {
                width: 24px;
                max-height: 24px;
            }
        }

        .options {
            margin-top: 3px;

            div {
                label {
                    line-height: 32px;
                }

                img {
                    margin-top: 4px;
                }
            }
        }
    }
}

.options {
    z-index: 3;
    position: absolute;
    background-color: white;
    width: calc(100% + 2px);
    margin-top: 7px;
    margin-left: -9px;

    border-radius: 0 0 5px 5px;
    border: 1px solid var(--colour-border);
    transition: all .25s ease;

    max-height: 0;
    opacity: 0;
    overflow: hidden;

    &.open {
        overflow-y: scroll;
        max-height: 250px;
        opacity: 1;
    }

    > div {
        padding: 0 8px;
    }

    @media only screen and (max-width: $Mobile) {
        margin-left: -7px;

        > div {
            padding: 0 6px;
        }
    }

    div {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: left;

        &.selected, &:hover {
            background-color: #f1f1f1;
        }

        label {
            font-size: 16px;
            cursor: pointer;
            margin-left: 8px;
            line-height: 36px;
        }
    }

    hr {
        margin: .1rem;
        border: 0 transparent;
        border-bottom: 1px dashed var(--colour-border);
    }
}

input, textarea, div.pretend-input {
    z-index: 0;

    font-family: var(--default-fonts);
    font-weight: 300;

    color: #282828;
    font-size: 18px;
    line-height: 1.33;
    padding: 2px 16px 0;
    height: 60px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid var(--colour-border);
    border-radius: 6px;
    -webkit-border-radius: 5px;
    margin: 0.33rem 0 1rem 0;

    transition: opacity .15s ease,
                color .15s ease;

    @media only screen and (max-width: $Mobile) {
        height: 46px;
    }

    //&:not(:placeholder-shown):not(:focus):invalid {
    //    background-color: #f2dede;
    //}

    &.medium {
        height: 48px;
    }

    &.small {
        height: 38px;
        font-size: 16px;

        &-font {
            font-size: 16px;
        }
    }

    &[type="date"] {
        position: relative;
        width: 100%;
        overflow: hidden;

        &::-webkit-calendar-picker-indicator {
            position: absolute;
            top: -150%;
            left: -150%;
            width: 300%;
            height: 300%;
            cursor: pointer;
            opacity: 0;
        }
    }
}

textarea, input[type="text"]:not(.small):not(.medium) {
    min-height: 4rem;
    max-height: 15rem;
    resize: none;
    padding: 12px 16px;
}

.radio-group {
    position: relative;
    margin: .5rem 0 1.5rem;
}

.radio-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.radio-container {
    display: grid;
    grid-template-columns: 1.75rem 275px;
    margin: .6rem 0;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    input {
        margin: 0;
    }

    @media only screen and (max-width: $Mobile) {
        grid-template-columns: 1.75rem 200px;
    }
}

input[type="radio"] {
    max-width: 19.5px;
    max-height: 19.5px;
    margin-right: .5rem;
}

.input-file-container {
    position: relative;
    transition: all .15s ease;
    box-sizing: border-box;

    background-color: #FAFAFA;
    border: 2px dashed var(--colour-border);
    border-radius: 8px;
    padding: 1.5rem;
    margin: .33rem 0 1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;

    p {
        margin: 0;

        em {
            color: var(--colour-light)
        }
    }

    .button-clear {
        position: absolute;

        border-radius: 8px;
        width: 2.5rem;
        height: 2.5rem;
        background: url("../assets/icons/cancel.svg");
        cursor: pointer;

        top: -.33rem;
        right: -.33rem;
    }

    input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        cursor: pointer;
        opacity: 0;
        margin: 0;

        top: 0;
        left: 0;

        &::file-selector-button {
            pointer-events: none;
        }
    }
}

label {
    font-weight: 300;
    color: var(--colour-grey);
    font-size: 1.1rem;

    @media only screen and (max-width: $Tablet) {
        font-size: 1rem;
    }
}

.input-attachment {
    margin-top: -.75rem;
    margin-bottom: 1rem;
}

.input-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    // Emulates flex gap
    margin-right: -12px;

    > div, button {
        // Emulates flex gap
        margin-right: 12px;

        flex: 1 0 200px;
        min-width: 200px;
    }

    &.always {
        > div, button {
            flex: 1 0 140px;
            min-width: 140px;
        }
    }
}

.pretend-input {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .title {
        text-transform: uppercase;
    }

    .miscellaneous {
        font-size: 14px;
    }
}

.input-container {
    position: relative;
    display: table;
    border-collapse: separate;
    width: 100%;

    height: 60px;
    margin: 0.33rem 0 1rem 0;

    @media only screen and (max-width: $Mobile) {
        height: 46px;
    }

    &.suffix input {
        padding: 2px 8px 0 16px;
        border-radius: 5px 0 0 5px;
        -webkit-border-radius: 5px 0 0 5px;
    }

    &.prefix input {
        padding: 2px 16px 0 8px;
        border-radius: 0 5px 5px 0;
        -webkit-border-radius: 0 5px 5px 0;
    }

    &.prefix.suffix input {
        padding: 2px 8px;
        border-radius: 0;
        -webkit-border-radius: 0;
    }

    input, div.pretend-input {
        position: relative;
        z-index: 1;
        float: left;
        width: 100%;
        display: table-cell;

        margin: 0 !important;

        &.suffix {
            border-radius: 5px 0 0 5px;
            -webkit-border-radius: 5px 0 0 5px;
        }

        &.prefix {
            border-radius: 0 5px 5px 0;
            -webkit-border-radius: 0 5px 5px 0;
        }

        //&:not(:placeholder-shown):not(:focus):invalid {
        //    background-color: #f2dede;
        //}
    }

    &.medium {
        height: 48px;
    }

    &.small {
        height: 38px;
    }
}

///* Chrome, Safari, Edge, Opera */
//input::-webkit-outer-spin-button,
//input::-webkit-inner-spin-button {
//    -webkit-appearance: none;
//    margin: 0;
//}
//
///* Firefox */
//input[type=number] {
//    -moz-appearance: textfield;
//}

.suffix-addon {
    @include input-addon();

    border-left: 0;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;

    &.available {
        background-color: #53b8b5;
        color: white;
    }

    &.not-available {
        background-color: #B86A65;
        color: white;
    }

    &.info-present {
        &.available, &.not-available {
            border-right: 0;
        }

        border-radius: 0;
        -webkit-border-radius: 0;
    }
}

.info-addon {
    @include input-addon();

    position: relative;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;

    border-left: 0;
    background-color: #128fdd;
    color: white;

    cursor: pointer;
    max-width: 48px;

    padding: 0 6px;
    margin: 0.25rem 0 1rem 0;

    img {
        user-select: none;
        pointer-events: none;
        margin-top: 6px;
        width: 33px;
        max-height: 33px;
    }

    &.visible {
        .info-box {
            transition: opacity .15s ease;
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.info-box, .input__tooltip {
    text-align: left !important;
    pointer-events: none;
    position: absolute;
    opacity: 0;

    z-index: 999;
    background-color: #e7f5ff;
    border: solid 1px #128fdd;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(23, 31, 70, 0.2);

    padding: 12px 10px;
    width: 300px;
    max-width: 300px;
    max-height: 500px;

    color: #444444;
    white-space: normal;
    transition: opacity .15s ease;

    &:before, &:after {
        content: ' ';
        display: block;
        border-style: solid;
        border-width: 0 .5em .8em .5em;
        border-color: transparent;
        position: absolute;
        left: calc(50% - .5rem);
    }

    &:before {
        border-bottom-color: #128fdd;
    }

    &:after {
        border-bottom-color: #e7f5ff;
    }

    &.top {
        bottom: calc(25% - 4px);

        &:before, &:after {
            top: calc(100% - 20px);
        }
    }

    &.right {
        left: calc(100% + 16px);

        &:before, &:after {
            left: -16px;
            transform: rotate(270deg);
        }

        &:after {
            left: calc(-16px + .1rem);
        }
    }

    &.bottom {
        top: calc(25% - 4px);

        &:before, &:after {
            bottom: calc(100% - 20px);
        }
    }

    &.left {
        right: calc(100% + 16px);

        &:before, &:after {
            left: calc(100%);
            transform: rotate(90deg);
        }

        &:after {
            left: calc(100% - .1rem)
        }
    }

    @media only screen and (max-width: $Mobile) {
        max-width: 290px;

        &.top {
            bottom: calc(100% + 16px);

            &:before, &:after {
                transform: rotate(180deg);
                top: 100%;
            }

            &:after {
                top: calc(100% - .1rem)
            }
        }

        &.right {
            left: -15px;

            &:before, &:after {
                left: 30px;
            }
        }

        &.bottom {
            top: calc(100% + 16px);

            &:before, &:after {
                transform: rotate(0deg);
                bottom: 100%;
            }

            &:after {
                bottom: calc(100% - .1rem)
            }
        }

        &.left {
            right: -15px;

            &:before, &:after {
                left: calc(100% - 45px);
            }
        }
    }
}

.prefix-addon {
    @include input-addon();

    border-right: 0;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
}

.selection-addon {
    cursor: pointer;

    span {
        user-select: none;
        font-size: 12px;
        vertical-align: top;

        img {
            height: 24px;
            width: 16px;
            padding-left: 4px;
        }
    }

    .hidden-input {
        position: absolute;
        opacity: 0;
        width: 50%;
        height: 100%;
    }

    &.disabled {
        pointer-events: none;
    }
}

.flag-addon {
    @extend .selection-addon;

    &.only-one {
        span {
            display: none;
        }
    }
}

.phone-container {
    position: relative;
    //z-index: 2;

    .country-list {
        z-index: 3;
        position: absolute;
        background-color: white;
        min-width: 220px;
        width: 100%;
        margin-top: -1px;
        margin-left: -9px;

        border-radius: 0 0 5px 5px;
        border: 1px solid var(--colour-border);
        transition: all .25s ease;

        max-height: 0;
        opacity: 0;
        overflow: hidden;

        &-item-highlighted {
            background-color: #f5f5f5;

            label {
                font-weight: 500;
            }
        }

        &.open {
            overflow-y: scroll;
            max-height: 200px;
            opacity: 1;
        }

        > div {
            padding: 0 8px;
        }

        @media only screen and (max-width: $Mobile) {
            margin-left: -7px;

            > div {
                padding: 0 6px;
            }
        }

        div {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: left;

            &.selected, &:hover {
                background-color: #f1f1f1;
            }

            label {
                font-size: 16px;
                cursor: pointer;
                margin-left: 8px;
                line-height: 36px;
            }
        }
    }
}

.toggle-container {
    label {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500 !important;
        color: #555555;
    }

    transition: all .25s ease;
    padding: 1.125rem 4rem 1.125rem 1rem;

    &.toggle-literal {
        padding: 1.125rem 6.5rem 1.125rem 1rem;
    }

    @media only screen and (max-width: $Laptop) {
        font-size: 17px;
        line-height: 24px; // 48
        padding: .75rem 3.5rem .75rem 1rem;

        &.toggle-literal {
            padding: .75rem 6rem .75rem 1rem;
        }
    }

    &.clickable {
        color: #282828;
        font-weight: 400;
        font-size: 1.1rem;
        cursor: pointer;
        user-select: none;
    }

    &.cursor-pointer, &.cursor-pointer label {
        cursor: pointer;
        user-select: none;
    }

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:hover:not(.small, .tiny, .minuscule) {
        background-color: #e3e9f0;
        border-radius: 6px;

        &.selected {
            .toggle-button > span {
                border-bottom: 0 solid transparent;
                transform: translate(0, -35%) rotate(0deg);
                border-right: 0 solid transparent;

                &:before, &:after {
                    position: absolute;
                    left: 11px;
                    content: ' ';
                    height: 28px;
                    width: 7px;
                    background-color: #b13f3f;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }

                @media only screen and (max-width: $Laptop) {
                    transform: translate(0, -45%) rotate(0deg);

                    &:before, &:after {
                        position: absolute;
                        left: 8px;
                        content: ' ';
                        height: 20px;
                        width: 5px;
                        background-color: #b13f3f;
                    }
                }
            }
        }
    }

    &.toggle-header {
        cursor: pointer;

        margin: -1rem -1rem 1rem;
        white-space: normal;

        @media only screen and (max-width: $Tablet) {
            line-height: 24px;

            margin: -.5rem -.5rem 0 -.5rem;
            padding: .5rem 3rem .5rem .5rem;
        }

        &:not(.expand) {
            margin-bottom: -1rem;

            @media only screen and (max-width: $Tablet) {
                margin: -.5rem -.5rem -.5rem -.5rem;
                padding: .5rem 3rem .5rem .5rem;
            }
        }
    }

    &.small, &.tiny, &.minuscule {
        padding: 0 2.5rem 0 0;
    }

    &.small {
        margin-bottom: 1rem;

        label {
            font-weight: 400 !important;
            font-size: 1.1rem;

            @media only screen and (max-width: $Tablet) {
                font-size: 1rem;
            }
        }
    }

    &.tiny {
        label {
            font-weight: 400 !important;
            font-size: 1.1rem;
        }
    }

    &.minuscule {
        label {
            font-weight: 400 !important;
            font-size: .9rem;
        }
    }

    &.magic {
        label {
            font-weight: 300 !important;
        }
    }
}

.toggle-literals {
    position: absolute;

    right: 1rem;
    top: calc(50% - 16px);

    height: 32px;
    line-height: 32px;

    margin: auto 0;
    transition: all .25s ease;

    span {
        transition: all .25s ease;

        &:first-child:not(.selected), &:last-child:not(.selected) {
            color: #C0C0C0;
        }
    }

    @media only screen and (max-width: $Laptop) {
        top: calc(50% - 12px);
        height: 24px;
        line-height: 24px;
    }

    &.small, &.tiny, &.minuscule {
        right: 0;
    }

    &.tiny, &.minuscule {
        top: calc(50% - 12px);
        height: 24px;
        line-height: 24px;
    }
}

.toggle-button {
    position: absolute;

    right: 1rem;
    top: calc(50% - 16px);

    border-radius: 4px;
    background: #FFFFFF;
    border: 2px solid var(--colour-border);
    box-sizing: border-box;

    height: 32px;
    width: 32px;

    margin: auto 0;
    transition: all .25s ease;

    > span {
        transition: all .25s ease;
    }

    &.selected {
        > span {
            display: inline-block;
            transform: translate(75%, -4px) rotate(45deg);
            height: 28px;
            width: 14px;
            border-bottom: 7px solid #78b13f;
            border-right: 7px solid #78b13f;
        }

        @media only screen and (max-width: $Laptop) {
            > span {
                transform: translate(65%, -4px) rotate(45deg);
                height: 20px;
                width: 12px;
                border-bottom: 5px solid #78b13f;
                border-right: 5px solid #78b13f;
            }
        }

        &.tiny {
            > span {
                transform: translate(65%, -4px) rotate(45deg);
                height: 20px;
                width: 12px;
                border-bottom: 5px solid #78b13f;
                border-right: 5px solid #78b13f;
            }
        }

        &.minuscule {
            > span {
                transform: translate(50%, -5px) rotate(45deg);
                height: 16px;
                width: 10px;
                border-bottom: 4px solid #78b13f;
                border-right: 4px solid #78b13f;
            }
        }
    }

    &.locked {
        > span {
            display: inline-block;
            transform: translate(75%, -4px) rotate(45deg);
            height: 28px;
            width: 14px;
            border-bottom: 7px solid #3f76b1;
            border-right: 7px solid #3f76b1;
        }

        @media only screen and (max-width: $Laptop) {
            > span {
                transform: translate(65%, -4px) rotate(45deg);
                height: 20px;
                width: 12px;
                border-bottom: 5px solid #3f76b1;
                border-right: 5px solid #3f76b1;
            }
        }
    }

    @media only screen and (max-width: $Laptop) {
        top: calc(50% - 12px);
        height: 24px;
        width: 24px;
    }

    &.small, &.tiny, &.minuscule {
        right: 0;
    }

    &.tiny {
        top: calc(50% - 12px);
        height: 24px;
        width: 24px;
    }

    &.minuscule {
        top: calc(50% - 9px);
        height: 18px;
        width: 18px;
    }
}
