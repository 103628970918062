.input__group {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.toggle--literal {
    display: grid;
    grid-template-columns: repeat(2, minmax(max-content, 1fr));

    position: relative;
    background-color: #fff;
    width: 125px;

    border-radius: .25rem;
    overflow: hidden;
    border: 1px solid var(--colour-border);

    div {
        display: grid;
        place-content: center;

        padding: var(--default-font-padding);
        white-space: nowrap;

        &:first-child {
            border-right: 1px solid var(--colour-border);
        }
    }

    &:before {
        position: absolute;
        content: '';
        background-color: var(--colour-disabled);
        height: 100%;
        width: 50%;
        left: 0;

        transition: all .25s ease;
    }

    &[data-disabled="true"] {
        background-color: var(--colour-disabled);
        color: var(--colour-disabled-font);

        &:before {
            background-color: #909598;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        opacity: 0;
        cursor: pointer;

        &:disabled {
            cursor: auto;
        }
    }
}

.toggle--literal-enabled {
    &:before {
        left: 50%;
        background-color: #2c7be5;
    }

    &[data-disabled="true"]:before {
        background-color: #909598;
    }
}

.collapsable__content {
    position: absolute;
    display: grid;
    grid-template-rows: 0fr;
    min-height: 0;
    max-height: 0;

    overflow: hidden;
    transition: all .2s ease;
    opacity: 0;
    border-radius: .25rem;
    border: 1px solid transparent;
    background-color: #fff;
}

.collapsable__container {
    position: relative;
    z-index: 1;

    &.open {
        .collapsable__content {
            grid-template-rows: 1fr;
            max-height: 450px;

            overflow-y: scroll;
            border: 1px solid var(--colour-border);
            opacity: 1;
        }
    }
}

.collapsable__item {
    padding: 0.75rem .75rem;

    line-height: 1;
    cursor: pointer;

    &:hover {
        background-color: #f1f1f1;
    }
}
