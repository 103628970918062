.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: calc(1rem * .5); }
.mb-2 { margin-bottom: calc(1rem * 1); }
.mb-3 { margin-bottom: calc(1rem * 1.5); }
.mb-4 { margin-bottom: calc(1rem * 2); }
.mb-5 { margin-bottom: calc(1rem * 2.5); }
.mb-6 { margin-bottom: calc(1rem * 3); }
.mb-7 { margin-bottom: calc(1rem * 3.5); }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: calc(1rem * .5); }
.mt-2 { margin-top: calc(1rem * 1); }
.mt-3 { margin-top: calc(1rem * 1.5); }
.mt-4 { margin-top: calc(1rem * 2); }
.mt-5 { margin-top: calc(1rem * 2.5); }
.mt-6 { margin-top: calc(1rem * 3); }
.mt-7 { margin-top: calc(1rem * 3.5); }

.my-0 { margin-bottom: 0; margin-top: 0; }
.my-1 { margin-bottom: calc(1rem * .5); margin-top: calc(1rem * .5); }
.my-2 { margin-bottom: calc(1rem * 1); margin-top: calc(1rem * 1); }
.my-3 { margin-bottom: calc(1rem * 1.5); margin-top: calc(1rem * 1.5); }
.my-4 { margin-bottom: calc(1rem * 2); margin-top: calc(1rem * 2); }
.my-5 { margin-bottom: calc(1rem * 2.5); margin-top: calc(1rem * 2.5); }
.my-6 { margin-bottom: calc(1rem * 3); margin-top: calc(1rem * 3); }
.my-7 { margin-bottom: calc(1rem * 3.5); margin-top: calc(1rem * 3.5); }

.gg-0 { grid-gap: 0; }
.gg-1 { grid-gap: calc(1rem * .5); }
.gg-2 { grid-gap: calc(1rem * 1); }
.gg-3 { grid-gap: calc(1rem * 1.5); }
.gg-4 { grid-gap: calc(1rem * 2); }
.gg-5 { grid-gap: calc(1rem * 2.5); }
.gg-6 { grid-gap: calc(1rem * 3); }
.gg-7 { grid-gap: calc(1rem * 3.5); }
