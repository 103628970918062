#__new {
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

    @import "./typography";
    @import "../layout"; // This is an older stylesheet
    @import "./form";
    @import "./inputs";
    @import "./buttons";
    @import "./sections";
    @import "./cards";
    @import "./lists";

    display: grid;
    grid-template-columns: 250px 1fr;
    width: 100%;
    height: 100vh;

    --colour-muted: #95aac9;
    --colour-disabled: #e9ecef;
    --colour-disabled-font: #6c7177;

    --default-font-colour: #12263f;
    --default-font-family: "Cerebri Sans", sans-serif;
    --default-font-size: .9375rem;
    --default-font-weight: 400;
    --default-font-padding: 0.375rem 0.75rem;
    //--default-font-padding: 0.5rem 0.75rem 0.25rem; // For CircularStd

    * {
        display: block;
        font-size: var(--default-font-size);
        font-weight: var(--default-font-weight);
        font-family: var(--default-font-family);
    }

    main {
        all: unset;

        background-color: #f9fbfd;
        padding: 0 2rem 6rem;
        position: relative;
        overflow: auto;
    }

    section {
        all: unset;

        position: relative;
        display: flex;
        flex-direction: column;
        padding: 1.5rem 0;
    }

    nav {
        all: unset;
        z-index: 1000;
        padding: 0.75rem 1.5rem;

        background-color: #fff;

        &.nav--sidebar {
            overflow-y: scroll;
        }

        &.nav--topbar {
            display: grid;
            grid-auto-flow: column;
            place-content: start;
            grid-gap: 1rem;

            position: fixed;
            top: 0;
            left: 250px;
            width: calc(100% - 250px);

            border: solid #e3ebf6;
            border-width: 0 0 1px 0;
        }

        .nav--links {
            display: grid;
            grid-auto-flow: row;
            grid-gap: 1rem;

            padding: .5rem 0;
        }

        .nav--link {
            display: grid;
            grid-template-columns: min-content 1fr;
            grid-gap: .5rem;

            color: #6e84a3;
            font-size: 1rem;
            font-weight: 300;
            text-decoration: none;

            transition: all .1s ease;

            span {
                display: grid;
                place-content: center;
                width: min-content;

                svg {
                    width: 18px;
                    height: 18px;
                    margin-bottom: 2px;

                    path {
                        transition: all .1s ease;
                        fill: #6e84a3;
                    }
                }
            }

            &:hover {
                color: black;

                svg {
                    path {
                        fill: black;
                    }
                }
            }
        }
    }

    hr {
        border: solid #e3ebf6;
        border-width: 0 0 1px 0;
    }

    .hidden {
        opacity: 0;
        pointer-events: none;

        &--partial {
            opacity: .2;
            pointer-events: auto;

            .hidden--partial {
                opacity: 1;
            }
        }
    }
}
