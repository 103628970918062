//@media (width < 1000px) {
//    * {
//        --font-size-h1: 1rem;
//    }
//}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6,
.p, p, .label, label {
    //margin: 0;

    font-family: var(--default-font-family);
    font-size: var(--default-font-size);
    font-weight: var(--default-font-weight);
    line-height: 1.5;
    color: var(--default-font-colour);

    .text--muted {
        color: var(--colour-muted);
    }
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    line-height: 1.1;
}

.h1, h1 {
    margin-bottom: 1.125rem;
    font-size: 1.5rem;
}

@media (min-width: 768px) {
    .h1, h1 {
        font-size:1.625rem;
    }
}

.h2, h2 {
    font-size: 1.25rem;
    margin-bottom: 1.125rem;
}

.h3, h3 {
    font-size: 1.0625rem;
    margin-bottom: .84375rem;
}

.h4, h4 {
    font-size: .9375rem;
    margin-bottom: .5625rem;
}

.h5, h5 {
    font-size: .8125rem;
    margin-bottom: .5625rem;
}

.h6, h6 {
    font-size: .625rem;
    margin-bottom: .5625rem;
}

.label, label {
    font-size: var(--default-font-size);
    margin-bottom: 0.5rem;

    &[for] {
        display: grid;
        grid-gap: .5rem;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, min-content);
        width: min-content;
        max-width: 100%;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span:last-child {
            display: inline-grid;
            align-items: center;
            place-content: center;
            height: 100%;
            width: min-content;

            white-space: nowrap;
            color: var(--colour-muted);
        }
    }
}

.small, small {
    font-size: .8125rem;
    font-weight: 400;
}

//p {
//    small {
//        color: #95aac9;
//        font-size: .85rem;
//        font-weight: 300;
//        font-family: "roboto",sans-serif;
//    }
//}
