@import "breakpoints";

$icon__trash-bin: "../assets/icons/trash.svg";
$icon__waiting: "../assets/icons/sand-timer.svg";
$icon__checkmark: "../assets/icons/checkmark.svg";

.table__container {
    --border: 1px solid var(--colour-border);

    border-spacing: 0;
    border: var(--border);
    border-radius: 5px;

    tr {
        &:nth-child(1n) {
            th, td {
                border-top: var(--border);
            }
        }

        &:first-of-type {
            th, td {
                border-top: none;

                &:first-child {
                    border-top-left-radius: 5px;
                }

                &:last-child {
                    border-top-right-radius: 5px;
                }
            }
        }

        &:last-of-type {
            th, td {
                &:first-child {
                    border-bottom-left-radius: 5px;
                }

                &:last-child {
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }

    th, td {
        height: 32px;
        padding: 0 8px;

        label {
            font-size: 1rem;
            line-height: 30px;
        }

        &:nth-child(1n) {
            border-left: var(--border);
        }

        &:first-child {
            border-left: none;
        }
    }

    *:focus {
        outline: none;
    }

    td:focus-within {
        box-shadow: 0 0 0 1px black inset;
    }

    td.disable--focus-within {
        box-shadow: none !important;
    }

    .cell--frozen, *:disabled {
        background-color: #f5f5f5;
    }

    .cell--frozen {
        min-width: 24px;
    }

    .cell--trash-bin-active,
    .cell--trash-bin:hover {
        transition: all .2s ease,
                    background-position 0s ease;
        background-color: #ff3e01;
        background-image: url($icon__trash-bin);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 75% 75%;
        cursor: pointer;

        * {
            pointer-events: none;
            transition: all .1s ease;
            color: transparent;
        }
    }

    .cell--waiting-active,
    .cell--waiting:hover {
        transition: all .2s ease,
                    background-position 0s ease;
        background-color: #f88f01;
        background-image: url($icon__waiting);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 75% 75%;
        cursor: pointer;

        * {
            pointer-events: none;
            transition: all .1s ease;
            color: transparent;
        }
    }

    .cell--checkmark-active,
    .cell--checkmark:hover {
        transition: all .2s ease,
        background-position 0s ease;
        background-color: #0c7040;
        background-image: url($icon__checkmark);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 75% 75%;
        cursor: pointer;

        * {
            pointer-events: none;
            transition: all .1s ease;
            color: transparent;
        }
    }

    .prefix--override {
        position: relative;
        margin: 0;
        border: none;
        height: 32px;
        border-radius: 0;

        img {
            margin-top: 2px;
        }

        &[class~=#{"prefix-addon"}] {
            border-right: var(--border)
        }

        input {
            padding: 0 8px;
            border-radius: 0;
            height: 32px;

            &:focus {
                box-shadow: 0 0 0 1px black inset;
            }
        }

        .country-list {
            min-width: 200px;
        }
    }

    // TODO second portion is to avoid existing css affecting this input.
    input, input[type="text"]:not(.small):not(.medium) {
        height: 29px;
        min-height: auto;
        max-height: none;
        padding: 0;
        margin: auto;

        border: none;
        border-radius: 0;

        font-size: 1rem;
        line-height: 30px;
    }
}
