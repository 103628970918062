.card__container {
    all: unset;

    position: relative;
    margin-bottom: 1.5rem;
    padding: 1rem 1.5rem;

    border: 1px solid #edf2f9;
    border-radius: 0.5rem;
    box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
}
