html,
body,
#root {
    // Fonts
    --default-fonts: "CircularStd", sans-serif;

    // Sizes
    --size-icon-default: 24px;

    // Colours
    --colour-dark: #282828;
    --colour-dark-grey: #444444;
    --colour-grey: #555555;
    --colour-gray: #555555;
    --colour-light: #acacac;

    --colour-light-grey: #fafafb;
    --colour-blue: #b2d0e2;
    --colour-yellow: #f5d9a1;
    --colour-green: #a0d5c8;
    --colour-dark-green: #44a1a7;
    --colour-darker-green: #25675f;
    --colour-pink: #f1bfcb;
    --colour-orange: #f6c7ac;
    --colour-purple: #d3c5e8;
    --colour-lime: #c1deb8;
    --colour-light-blue: #c4f0f6;
    --colour-red: #f6a6a6;

    --colour-icon-primary: #FFFFFF;

    --colour-link-light: #5296ff;
    --colour-link: #007bff;
    --colour-link-highlight: #004895;

    --colour-primary: #77A6C3;
    --colour-secondary: #244a66;
    --colour-gold: #e7c674;

    --color-primary-background: #deedf6;
    --color-primary-background-light: #eaf4fa;
    --colour-yellow-background: #fff8e6;

    --colour-border: #D7DFE9;
    --colour-border-darkened: #c3d8e7;
    --colour-border-yellow: #ffecb4;

    --colour-hover: #e3e9f0;
    --colour-hover-darkened: #d0dfea;

    //--nav-selected-bg: #101218;
    //--desktop-nav-bg: #1B1D23;
    //--desktop-nav-active-colour: #5363F6;
    //--desktop-nav-link-colour: #D4D9E1;
    //--button-yellow: #FBBE18;
    //--button-yellow-highlight: #ce9d15;
}
