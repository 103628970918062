$filled_envelope: "../assets/icons/envelope-filled.svg";
$filled_phone: "../assets/icons/phone-filled.svg";
$filled_signature: "../assets/icons/autograph.svg";

@mixin icon-style($url, $className, $colour, $size) {
    [class~=#{"icon--" + $className}] {
        position: relative;

        background-color: $colour;
        mask-image: url($url);
        mask-size: 100% 100%;

        width: $size;

        &:before {
            content: "";
            display: block;
            padding-top: 100%; 	/* ratio of 1:1*/
        }
    }
}

@include icon-style($filled_envelope, "filled__envelope", var(--colour-grey), var(--size-icon-default));
@include icon-style($filled_phone, "filled__phone", var(--colour-grey), var(--size-icon-default));
@include icon-style($filled_signature, "filled__signature", var(--colour-grey), var(--size-icon-default));
