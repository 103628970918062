@import "breakpoints";

$icon__add: "../assets/icons/add_1.svg";
$icon__copy-link: "../assets/icons/copy-link.svg";

// Form, button container
.form__b-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: fixed;
    width: 100%;
    z-index: 2;

    left: 0;
    bottom: calc(1.5rem + 5px);
    transition: all .15s ease;

    @media only screen and (max-width: $Laptop) {
        bottom: calc(1rem);
    }

    @media only screen and (max-width: $Mobile) {
        bottom: 0;
    }

    --colour-positive: var(--colour-darker-green);
    --colour-neutral: var(--colour-secondary);

    --colour-primary: var(--colour-positive);
}

.form__button--single {
    text-align: center;
    font-family: var(--default-fonts);
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 1px;
    height: 62px;
    cursor: pointer;

    transition: all .25s ease;

    background-color: var(--colour-primary);
    border: 2px solid var(--colour-primary);
    color: white;
    min-width: 256px;
    padding: 0 2rem;
    user-select: none;
    border-radius: 6px;

    &:hover {
        box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
    }

    @media only screen and (max-width: $Laptop) {
        height: 56px;
    }

    @media only screen and (max-width: $Mobile) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        font-size: 22px;
    }

    &:disabled {
        background-color: transparent;
        color: var(--colour-primary);
    }
}

.form__buttons {
    button {
        text-align: center;
        font-family: var(--default-fonts);
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 1px;
        height: 62px;
        cursor: pointer;

        transition: all .25s ease;

        min-width: 256px;
        padding: 0 2rem;
        user-select: none;

        &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        &.form__button--primary {
            background-color: var(--colour-primary);
            border: 2px solid var(--colour-primary);
            color: white;
        }

        &.form__button--secondary {
            background-color: white;
            border: 2px solid var(--colour-primary);
            transition: all .25s ease,
                        background-color .33s ease;
            color: #282828;

            &:disabled {
                background-color: #d5dce1;
            }

            background-image: linear-gradient(
                45deg,
                white 25%,
                transparent 25%,
                transparent 50%,
                white 50%,
                white 75%,
                transparent 75%,
                transparent
            );

            background-size: 2rem 2rem;
            animation: 1s linear infinite loading-stripes;

            @keyframes loading-stripes {
                0% {
                    background-position: 2rem 0;
                }
                to {
                    background-position: 0 0;
                }
            }
        }

        &:hover {
            box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
        }

        @media only screen and (max-width: $Laptop) {
            height: 56px;
        }

        @media only screen and (max-width: $Mobile) {
            min-width: 0;
            width: 100%;
            max-width: 100%;
            height: 48px;
            padding: 0 1rem;

            border-radius: 0 !important;
            white-space: nowrap;
            font-size: 18px;
        }

        &:disabled {
            background-color: transparent;
            color: var(--colour-primary);
        }
    }
}

.button__pill {
    padding: .35rem .75rem .25rem;
    margin-right: .75rem;

    font-size: 14px;
    line-height: 14px;

    border-radius: 32px;
    background-color: var(--color-primary-background);
    border-color: var(--colour-border-darkened);
    transition: all .25s ease;
    cursor: pointer;

    &:not(&--selected):hover {
        background-color: #c6ddea;
        border-color: #c6ddea;
    }

    &--selected {
        color: white;
        background-color: var(--colour-secondary);
        border-color: var(--colour-secondary);
    }
}

.btn, .button {
    font-family: 'CircularStd', sans-serif;
    font-style: normal;
    font-weight: 500;
    width: 200px;
    height: 50px;
    line-height: 50px;

    font-size: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05rem;

    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--colour-secondary);
    user-select: none;

    background: var(--colour-secondary);
    color: white;
    cursor: pointer;
    transition: all .25s ease;
    outline: none;

    &:hover:not(:disabled) {
        text-decoration: none !important;
        border: 1px solid #305b7b;
        background: #305b7b;
        color: #FFFFFF;
        box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
    }

    &.stretch {
        width: auto;
        padding: 0 1rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.max-width {
        width: 100%;
        padding: 0 1rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.medium {
        min-width: 150px;
    }

    &.noborder {
        border: none;
    }

    &.secondary {
        background: #FFFFFF;
        color: #244A66;

        :hover {
            color: var(--colour-secondary);
        }
    }

    &.inactive {
        pointer-events: none;
        color: transparent;
    }

    &.contrasted {
        padding: 0 1rem;
        width: auto;
        font-weight: 400;
        background-color: var(--colour-link-light);
        border: 1px solid var(--colour-link-light);
    }

    @media only screen and (max-width: $Mobile) {
        max-width: 100%;
    }

    @media only screen and (max-width: $MobileSmall) {
        font-size: 17px;
        width: 180px;
        height: 46px;
        line-height: 46px;
    }

    &:disabled {
        background: #FFFFFF;
        color: #244A66;
    }

    &.squared {
        font-size: 26px;
        width: 50px !important;

        @media only screen and (max-width: $Mobile) {
            width: 40px !important;
            height: 40px !important;
            line-height: 40px;
        }
    }
}

.button__simple {
    font-family: 'CircularStd', sans-serif;
    min-width: 150px;
    width: min-content;
    height: min-content;
    padding: .25rem 1rem;
    border-radius: 6px;

    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25;
    white-space: nowrap;

    transition: all .2s ease;
    user-select: none;
    cursor: pointer;
    color: var(--colour-grey);
    background-color: white;
    border: 2px solid var(--colour-border);

    &:hover {
        background-color: #e9e9ef;
        border: 2px solid var(--colour-border);
    }

    &:active {
        background-color: #d0d0d7;
    }
}

.button-tool__container {
    height: min(100%, 24px);
    width: min(100%, 24px);

    border-radius: 4px;
    border: 1px solid var(--colour-border);
    background-color: #e1e6ea;
    transition: all .15s ease;
    cursor: pointer;

    .button-tool__icon {
        height: min(100%, 24px);
        width: min(100%, 24px);

        background-color: #545454;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 75% 75%;

        &--copy-link {
            mask-image: url($icon__copy-link);
        }
    }

    &:hover {
        background-color: #cdd2d5;
    }

    &:active {
        background-color: #a2a6a9;
    }
}

.btn-sms-verification {
    width: 50%;
    margin: calc(.33rem + 31px) 0 1rem;
    height: 60px;

    &:disabled:hover {
        border: 2px solid var(--colour-secondary);
        background: var(--colour-secondary);
        box-shadow: 0 0 0 0 rgba(23, 31, 70, 0);
    }

    &.sent {
        background-color: white;
        color: var(--colour-secondary);
        border: 2px solid var(--colour-secondary);
    }
}

// TODO, clean up
.btn-country {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: left;

    border: solid 2px var(--colour-secondary);
    border-radius: 50px;
    break-inside: avoid;

    transition: all .25s ease;
    background-color: white;
    will-change: transform;
    cursor: pointer;
    width: 250px;
    margin: 1rem auto;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        white-space: nowrap;
        color: var(--colour-secondary);
        font-size: 1.25rem;

        &:last-child {
            padding: 0 1.5rem 0 1rem;
        }
    }

    img {
        margin: -6px;
        border: solid 2px transparent;
    }

    .img-border {
        position: absolute;
        border: solid 2px var(--colour-secondary);

        height: 44px;
        margin-left: -2px;
        border-radius: 50px;
    }

    &:hover {
        box-shadow: 0 0 10px rgba(23, 31, 70, 0.33);
    }
}

// TODO, clean up
.btn-service {
    break-inside: avoid;
    will-change: transform;

    display: flex;
    flex-direction: row;
    justify-content: left;
    pointer-events: auto;

    background-color: white;
    box-shadow: 0 4px 20px rgb(23 31 70 / 16%);
    border-radius: 6px;
    border: 2px solid var(--colour-border);
    box-sizing: border-box;
    cursor: pointer;
    width: 250px;
    margin: 1rem auto;

    transition: all .2s ease;

    img {
        min-width: 56px;
        min-height: 56px;
        max-height: 56px;
        padding: 8px 16px 8px 8px;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        line-height: 1.25;
        font-size: 1.25rem;
        white-space: nowrap;
        color: var(--colour-secondary);
    }

    &:hover {
        box-shadow: 0 4px 20px rgb(23 31 70 / 10%),
        inset 0 0 10px rgb(23 31 70 / 20%);
    }
}

.btn-contract {
    break-inside: avoid;
    will-change: transform;

    display: flex;
    flex-direction: row;
    justify-content: left;
    pointer-events: auto;

    background-color: white;
    box-shadow: 0 4px 20px rgb(23 31 70 / 16%);
    border-radius: 6px;
    border: 2px solid var(--colour-border);
    box-sizing: border-box;
    cursor: pointer;
    width: 250px;
    margin: -2px auto;
    padding: 4px 8px;

    &:hover {
        box-shadow: 0 4px 20px rgb(23 31 70 / 10%),
        inset 0 0 10px rgb(23 31 70 / 20%);
    }

    div {
        position: absolute;
        height: 24px;
        width: 24px;
    }

    &.start {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    &.middle {
        border-radius: 0;
    }

    &.end {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-bottom: 1rem;
    }

    .enabled {
        background: url("../assets/icons/sign_document.svg") no-repeat;
        right: 6px;
    }

    .preview {
        &.enabled {
            background: url("../assets/icons/magnifying-glass.svg") no-repeat;
            right: 6px;
        }
    }

    .disabled {
        background: url("../assets/icons/cancel.svg") no-repeat;
        right: 6px;
    }
}

.column-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    width: 100%;

    &.large {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
}

.button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &-left {
        justify-content: left;
    }

    &-right {
        justify-content: right;
    }

    &-space-around {
        justify-content: space-around;

        @media only screen and (max-width: $Mobile) {
            justify-content: space-between;
        }
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.toggle__button {
    min-width: 150px;
    padding: .25rem 1rem;

    font-size: 1.1rem;

    cursor: pointer;
    user-select: none;
    transition: all .15s ease,
                background-color .25s ease;

    background-color: #d1d2da;
    box-shadow: 0 4px 20px rgb(23 31 70 / 16%);
    border: 2px solid var(--colour-border);

    &:nth-child(n) {
        border-left-width: 1px;
        border-right-width: 1px;
    }

    &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-left-width: 2px;
    }

    &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-right-width: 2px;
    }

    &--active {
        background-color: white;
        cursor: default;
        //box-shadow: 0 4px 20px rgb(23 31 70 / 10%),
        //inset 0 0 5px rgb(23 31 70 / 20%);
    }

    &:not(&--active):hover {
        background-color: #e6e6ec;
    }
}

.button__icon {
    padding: 0;
    margin: 0;

    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
        height: 100%;
        width: 100%;
    }

    &--add {
        width: 32px;
        height: 32px;

        --colour-icon-primary: #58b95d;

        &:hover {
            --colour-icon-primary: #339a38;
        }
    }
}

.buttons__group--floating {
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: fixed;
    width: 100%;
    z-index: 1;

    left: 0;
    bottom: calc(1.5rem + 5px);
    transition: all .15s ease;

    @media only screen and (max-width: $Laptop) {
        bottom: calc(1rem);
    }

    @media only screen and (max-width: $TabletSmall) {
        bottom: 0;
    }
}

.button__navigation {
    cursor: pointer;
    transition: all .25s ease;
    text-align: center;
    font-family: var(--default-fonts);
    user-select: none;

    &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .spinner {
        transform: scale(.66);
    }

    &--large {
        height: 62px;
        min-width: 256px;
        padding: 0 2rem;

        font-weight: 400;
        font-size: 24px;
        letter-spacing: 1px;

        border: 2px solid #244A66;
        color: #244A66;
        background-color: white;

        @media only screen and (max-width: $Laptop) {
            height: 56px;
        }

        @media only screen and (max-width: $TabletSmall) {
            border: 0 solid transparent;
            border-top: 2px solid var(--colour-secondary);
            min-width: 50vw;
            font-size: 22px;
        }
    }

    &:hover {
        box-shadow: 0 4px 15px 0 rgba(23, 31, 70, 0.16);
    }
}
