.spinner {
    width: 4rem;
    height: 4rem;
    position: relative;
    margin: auto;

    &.absolute {
        position: absolute;

        top: calc(50% - 2rem);
        left: calc(50% - 2rem);
    }

    div {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    div:before {
        content: "";
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: var(--colour-secondary);
        border-radius: 100%;
        animation: bounceDelay 1.2s infinite ease-in-out both;
    }

    .circle-2 {
        transform: rotate(30deg);
    }
    .circle-2:before {
        animation-delay: -1.1s;
    }

    .circle-3 {
        transform: rotate(60deg);
    }
    .circle-3:before {
        animation-delay: -1s;
    }

    .circle-4 {
        transform: rotate(90deg);
    }
    .circle-4:before {
        animation-delay: -0.9s;
    }

    .circle-5 {
        transform: rotate(120deg);
    }
    .circle-5:before {
        animation-delay: -0.8s;
    }

    .circle-6 {
        transform: rotate(150deg);
    }
    .circle-6:before {
        animation-delay: -0.7s;
    }

    .circle-7 {
        transform: rotate(180deg);
    }
    .circle-7:before {
        animation-delay: -0.6s;
    }

    .circle-8 {
        transform: rotate(210deg);
    }
    .circle-8:before {
        animation-delay: -0.5s;
    }

    .circle-9 {
        transform: rotate(240deg);
    }
    .circle-9:before {
        animation-delay: -0.4s;
    }

    .circle-10 {
        transform: rotate(270deg);
    }
    .circle-10:before {
        animation-delay: -0.3s;
    }

    .circle-11 {
        transform: rotate(300deg);
    }
    .circle-11:before {
        animation-delay: -0.2s;
    }

    .circle-12 {
        transform: rotate(330deg);
    }
    .circle-12:before {
        animation-delay: -0.1s;
    }

    @keyframes bounceDelay {
        0%,
        80%,
        100% {
            transform: scale(0);
        }
        40% {
            transform: scale(1);
        }
    }
}
