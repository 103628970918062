$chevron-down: "../../assets/icons/chevron-down.svg";

select, option, input, textarea {
    all: unset;
    box-sizing: border-box;

    font-family: var(--default-font-family);
    font-weight: var(--default-font-weight);
    font-size: var(--default-font-size);
    color: var(--default-font-colour);
    line-height: 1.5;

    &::placeholder {
        color: #b1c2d9;
    }

    &:disabled {
        background-color: var(--colour-disabled);
    }
}

select {
    padding: var(--default-font-padding);
    padding-right: 2rem;

    cursor: pointer;
    background-image: url($chevron-down);
    background-repeat: no-repeat;
    background-position: right 4px center;
    background-size: 24px 24px;

    &:disabled {
        cursor: auto;
    }
}

textarea {
    min-height: 5rem;
    max-height: 20rem;
    resize: none;
}

input, textarea {
    padding: var(--default-font-padding);

    &[type="date"] {
        position: relative;
        width: 100%;
        overflow: hidden;

        &::-webkit-calendar-picker-indicator {
            position: absolute;
            top: -150%;
            left: -150%;
            width: 300%;
            height: 300%;
            cursor: pointer;
            opacity: 0;
        }
    }
}

select, input, textarea {
    display: block;

    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid var(--colour-border);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
}

form {
    input, select, textarea,
    .collapsable__content {
        width: 100%;
    }
}
