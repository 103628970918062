@import "breakpoints";
@import "variables";
@import "backgrounds";
@import "typography";
@import "navigation";
@import "font-faces";
@import "form";
@import "grid";
@import "helpers";
@import "layout";
@import "miscellaneous";
@import "buttons";
@import "funnel";
@import "animations";
@import "loading-indicators";
@import "card";
@import "border";
@import "inputs";
@import "image";
@import "icons";
@import "envelopes";
@import "tables";
@import "svgs";
@import "toggle";
@import "info";
@import "toasts";
@import "veis";
@import "botpress";
@import "new/application";

html,
body {
    line-height: 1.55;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;

    color: var(--colour-grey);
    font-family: var(--default-fonts);
}

*, ::after, ::before {
    box-sizing: border-box;
}

main {
    padding: 4rem 0 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    overflow: hidden; // TODO: Could this cause problems?
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 4rem 0;

    @media only screen and (max-width: $Tablet) {
        padding: 3rem 0;

        &:first-child {
            padding-top: 2rem;
        }
    }

    &.overflow-hide {
        overflow: hidden;
    }

    &.large {
        padding: 6rem 0;

        @media only screen and (max-width: $Tablet) {
            padding: 5rem 0;

            &:first-child {
                padding-top: 3rem;
            }
        }
    }

    &.cover {
        flex: none;
        min-height: 100vh;

        &:first-child {
            min-height: calc(100vh - 8rem);

            &.section-center {
                min-height: calc(100vh - 4rem);
            }

            @media only screen and (max-width: $Tablet) {
                min-height: calc(100vh - 6rem);

                &.section-center {
                    min-height: calc(100vh - 4rem);
                }
            }
        }

        &:first-child:last-child {
            min-height: calc(100vh - 8rem);

            &.section-center {
                min-height: calc(100vh - 4rem);
            }

            @media only screen and (max-width: $Tablet) {
                min-height: calc(100vh - 6rem);

                &.section-center {
                    min-height: calc(100vh - 4rem);
                }
            }
        }
    }

    &.section-center {
        justify-content: center;
    }
}

.group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
}
