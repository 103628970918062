$CloseButton: "../assets/icons/toasts/close.svg";

.toast__root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    pointer-events: none;
    padding-top: 10px;

    display: grid;
    justify-content: center;
    grid-auto-rows: min-content;
}

.toast__container {
    position: relative;
    width: min(95vw, 380px);
    padding: 6px 28px 6px 12px;
    margin-top: 6px;

    pointer-events: auto;
    cursor: default;

    border-radius: 4px;
    background-color: #3282b8;
    color: white;

    transition: margin-bottom .5s ease,
                margin-top .5s ease;
}

.toast__header {
    font-size: 1.1rem;
    margin-bottom: .15rem;
}

.toast__body {
    font-size: 1rem;
    font-weight: 300;
}

.toast__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 28px;
    border-radius: 0 4px 0 6px;

    cursor: pointer;
    transition: background-color .2s ease;
    background-image: url($CloseButton);
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: right 7px top 7px;

    &:hover {
        background-color: rgba(253,255,255,0.25);
    }
}

.toast__level {
    &--success {
        background-color: #0c7040;
    }
    &--info {
        background-color: #244a66;
    }

    &--warning {
        background-color: #f88f01;
    }

    &--error {
        background-color: #c72c41;
    }
}

.toast__animation {
    &--slide-in {
        animation-duration: .5s;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-name: slide-in;

        @keyframes slide-in {
            0% {
                opacity: 0;
                transform: translateY(25px);
            }
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    &--slide-out {
        animation-duration: .5s;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-name: slide-out;

        @keyframes slide-out {
            0% {
                opacity: 1;
                transform: translateX(0);
            }
            100% {
                pointer-events: none;
                opacity: 0;
                transform: translateX(-25px);
            }
        }
    }
}
